body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121212;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root, .container {
  height: 100%;
  width: 100%;
}


.card-wrapper:hover > .card-image {
  filter: brightness(50%);
}

.card-wrapper:hover > .card-button-area{
  display: flex;
}

.card-button-area {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  justify-content: space-around;
  align-items: flex-end;
}

.player-place {
  position: absolute;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.player-place:hover {
  transform: scale(1.5);
}

.card-selected {
  transform: scale(1.1);
}

.name-field input {
  color: #fff;
  text-align: center;
}
.card-container {
  max-width: 100vw;
  overflow-x: auto;
}


.card-container > div {
  width: fit-content;
}


.green-screen {

  background-color: #031d07;
}
